<div id="bridgedrawing" #bridgedrawing [ngStyle]="stylebridge">
  <div class="bridgestruct">
    <div class="bridgetop" (click)="sideCarPosition()">
      <div class="bridgecar" #sidecar></div>
      <div class="bridgefile">
        <div class="bridgeleft"></div>
        <div class="bridgemid" #bodybridge></div>
        <div class="bridgeright"></div>
      </div>
    </div>
    <div class="bridgedown">
      <div class="pilarleft">
        <div class="pilarleftbody"></div>
        <div class="pilarlefttop"></div>
      </div>
      <div class="bridgemidbottom" #bridgebottom>
        <div class="bridgehook" #hook></div>
        <div class="bridgehookline" #hookline></div>
        <div class="bridgehooktip" #hooktip></div>
      </div>
      <div class="pilarright">
        <div class="pilarrighttop"></div>
        <div class="pilarrightbody"></div>
      </div>
    </div>
  </div>
</div>
