import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserModel } from 'src/app/models/usersmodel';
import { UsersRepository } from 'src/app/services/repository/users.repository';

@Component({
  selector: 'app-caduser',
  templateUrl: './caduser.component.html',
  styleUrls: ['./caduser.component.scss'],
})
export class CaduserComponent {
  public loading: boolean = false;
  public title: string = 'Cadastro de Usuário';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    public dialogRef: MatDialogRef<CaduserComponent>,
    public repository: UsersRepository
  ) {}

  async send() {
    this.loading = true;
    try {
      console.log('Item data => ', this.data.toJson());
      const res = await this.repository.insert(this.data);
      console.log('Insert Response => ', res);

      this.dialogRef.close(this.data);
    } catch (error) {}
    this.loading = false;
  }
}
