import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

type InputType = 'text' | 'password' | 'number';

@Component({
  selector: 'inputcomponent',
  templateUrl: './inputcomponent.component.html',
  styleUrls: ['./inputcomponent.component.scss'],
})
export class InputcomponentComponent implements OnInit {
  @Input() marginLeft: string = '2px';
  @Input() marginBottom: string = '2px';
  @Input() marginTop: string = '2px';
  @Input() marginRight: string = '2px';
  @Input() type: InputType = 'text';
  @Input() hinttext: string;
  @Input() error: string;
  @Input() disabled: boolean = false;
  @Input() autocomplete: string = 'new-password';
  @Input() clearbutton: boolean = false;

  @Input() data: string;
  @Output() dataChange = new EventEmitter<string>();
  @Output() onEnter = new EventEmitter<boolean>();

  @ViewChild('inputer') inputer: ElementRef;

  public focused = false;

  constructor() {}

  ngOnInit(): void {}

  public onChange() {
    this.dataChange.emit(this.data);
  }

  public onClear() {
    this.data = '';
    this.dataChange.emit(null);
  }

  public onEnterPressed() {
    this.onEnter.emit(true);
  }

  public setFocus() {
    this.focused = true;
    this.inputer.nativeElement.focus();
  }
}
