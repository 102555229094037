import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BridgeModel } from 'src/app/models/bridgemodel';
import { LoadCellModel } from 'src/app/models/loadcellmodel';
import { BridgeRepository } from 'src/app/services/repository/bridge.repository';
import { LoadCellsRepository } from 'src/app/services/repository/loadcell.repository';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';

@Component({
  selector: 'app-cadloadcell',
  templateUrl: './cadloadcell.component.html',
  styleUrls: ['./cadloadcell.component.scss']
})
export class CadloadcellComponent  implements OnInit{
  @Input() title: string = 'Cadastro de Células de Carga';
  public bridges: LoadCellModel[] = [];
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LoadCellModel,
    public dialogRef: MatDialogRef<CadloadcellComponent>,
    public repository: LoadCellsRepository,
    public bridgerepo: BridgeRepository
  ) {}

  ngOnInit() {
    this.getBridges();
  }

  async getBridges() {
    this.loading = true;
    try {
      this.bridges = ArrayInterfaceToArrayClass(
        await this.bridgerepo.getAll(),
        BridgeModel
      );
    } catch (error) {}
    this.loading = false;
  }

  async send() {
    this.loading = true;
    try {
      console.log('Item data => ', this.data.toJson());
      const res = await this.repository.insert(this.data);
      console.log('Insert Response => ', res);

      this.dialogRef.close(this.data);
    } catch (error) {}
    this.loading = false;
  }
}