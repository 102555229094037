import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BridgeModel } from 'src/app/models/bridgemodel';

@Component({
  selector: 'bridgedrawing',
  templateUrl: './bridgedrawing.component.html',
  styleUrls: ['./bridgedrawing.component.scss'],
})
export class BridgedrawingComponent implements OnInit {
  @Input() height: string = '100%';
  @Input() width: string = '100%';
  @Input() zoom: string = '100%';
  @Input() bridge: BridgeModel = new BridgeModel();
  @ViewChild('bodybridge') bodybridge: ElementRef<HTMLElement>;
  @ViewChild('sidecar') sidecar: ElementRef<HTMLElement>;
  @ViewChild('hook') hook: ElementRef<HTMLElement>;
  @ViewChild('hookline') hookline: ElementRef<HTMLElement>;
  @ViewChild('hooktip') hooktip: ElementRef<HTMLElement>;
  @ViewChild('bridgebottom') bridgebottom: ElementRef<HTMLElement>;

  winivalue: number = 0;
  hinivalue: number = 0;

  intervalwatcher = null;

  constructor() {
    // this.bridge.width = '200';
    // this.bridge.height = '300';
    // this.bridge.positionX = '150';
    // this.bridge.positionZ = '120';
  }

  get stylebridge() {
    return {
      height: this.height,
      width: this.width,
      zoom: this.zoom,
    };
  }

  ngOnInit(): void {}

  watcher() {
    this.intervalwatcher = setInterval(() => {
      if (
        this.hinivalue != this.bodybridge.nativeElement.offsetHeight ||
        this.winivalue != this.bodybridge.nativeElement.offsetWidth
      ) {
        this.sideCarPosition();
      }
    }, 20);
  }

  ngAfterViewInit() {
    this.hinivalue = this.bodybridge.nativeElement.offsetHeight;
    this.winivalue = this.bodybridge.nativeElement.offsetWidth;

    this.sideCarPosition();
    this.watcher();
  }

  ngOnDestroy() {
    try {
      clearInterval(this.intervalwatcher);
    } catch (error) {}
  }

  sideCarPosition() {
    const ini = this.bodybridge.nativeElement.offsetLeft;
    const width = this.bodybridge.nativeElement.offsetWidth;

    const sidecarsize = this.sidecar.nativeElement.offsetWidth;

    const end = width + ini - sidecarsize;

    let proportion =
      ini + (this.bridge.positionX_value * end) / this.bridge.width_value;

    proportion = proportion <= end ? proportion : end;

    this.sidecar.nativeElement.style.left = `${proportion}px`;

    //hook configuration

    const hooksize = this.hook.nativeElement.offsetWidth;
    const hookpos = proportion - sidecarsize / 2 - hooksize / 2;
    const hooklinepos = proportion - sidecarsize / 2;

    this.hook.nativeElement.style.left = `${hookpos}px`;
    this.hookline.nativeElement.style.left = `${hooklinepos}px`;
    this.hooktip.nativeElement.style.left = `${hookpos}px`;

    //line hook distance
    const hooktipsize = this.hooktip.nativeElement.offsetHeight;

    const maxlinesize =
      this.bridgebottom.nativeElement.offsetHeight -
      this.hook.nativeElement.offsetHeight -
      this.hooktip.nativeElement.offsetHeight;
    let linedistance =
      (this.bridge.positionZ_value * maxlinesize) / this.bridge.height_value;

    linedistance = linedistance <= maxlinesize ? linedistance : maxlinesize;

    this.hookline.nativeElement.style.height = `${linedistance}px`;
    this.hooktip.nativeElement.style.top = `${
      linedistance + hooktipsize / 2
    }px`;
  }
}
