import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'buttoncomponent',
  templateUrl: './buttoncomponent.component.html',
  styleUrls: ['./buttoncomponent.component.scss'],
})
export class ButtoncomponentComponent implements OnInit {
  @Input() text: string = 'Button';
  @Input() iconlocation: string = '';
  @Input() background: string = '#022331';
  @Input() color: string = '#fff';
  @Output() onClick = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  get getIcon() {
    return `url("${this.iconlocation}")`;
  }

  public clicked() {
    this.onClick.emit(true);
  }
}
