<section id="modal">
  <div class="headermodal">
    <div class="titlemodal">{{ title }}</div>
    <icon
      icon="/assets/icons/closeicon.svg"
      width="20px"
      height="20px"
      class="iconmodal"
      mat-dialog-close
    ></icon>
  </div>
  <div class="bodymodal">
    <div class="formdata">
      <inputcomponent
        hinttext="ID"
        marginBottom="4px"
        [(data)]="data.idUsuario"
        type="text"
        style="width: 20%"
        disabled="true"
      ></inputcomponent>
      <inputcomponent
        hinttext="Nome"
        marginBottom="4px"
        [(data)]="data.Nome"
        type="text"
        style="width: 80%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Usuário"
        marginBottom="4px"
        [(data)]="data.Usuario"
        type="text"
        style="width: 50%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Senha"
        marginBottom="4px"
        [(data)]="data.Senha"
        type="password"
        style="width: 50%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Função"
        marginBottom="4px"
        [(data)]="data.Funcao"
        type="text"
        style="width: 50%"
      ></inputcomponent>
      <billboard
        title="Configurações"
        height="45px"
        width="100%"
        style="width: 100%"
      >
        <switch
          [data]="data.isAtivo"
          (onChange)="data.isAtivo = $event"
          >Usuário ativo?</switch
        >
      </billboard>
    </div>
    <div style="float: right; margin-top: 12px;">
      <buttoncomponent
        text="Salvar"
        background="var(--color-primary)"
        (onClick)="send()"
      ></buttoncomponent>
    </div>
  </div>
</section>
<spinner *ngIf="loading">Carregando...</spinner>
