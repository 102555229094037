import { Injectable } from '@angular/core';
import { IDevice } from 'src/app/interfaces/deviceinterface';
import { ConnectionService } from './connection.service';
import { ENDPOINTS } from './enpoints';

@Injectable({
  providedIn: 'root',
})
export class DeviceRepository {
  constructor(private conn: ConnectionService) {}

  async getAll(): Promise<IDevice[]> {
    try {
      return (await this.conn.get(ENDPOINTS().devices)) as IDevice[];
    } catch (error) {
      console.error(error);
    }
  }
}
