import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ITableColumnConfig,
  ITableHeaderConfig,
} from './tablecomponent.constants';

@Component({
  selector: 'tablecomponent',
  templateUrl: './tablecomponent.component.html',
  styleUrls: ['./tablecomponent.component.scss'],
})
export class TablecomponentComponent implements OnInit {
  @Input() title: string;
  @Input() download: boolean = false;
  @Input() _values: any[] = [];
  @Input() height: string = 'auto';
  @Input() columnsConfig: ITableColumnConfig[] = [];
  @Input() headerConfig: ITableHeaderConfig = {};
  public headerConfig_: ITableHeaderConfig = {
    color: '#000',
    background: '#d1d1d1',
    'font-size': '12px',
  };

  @Input() set values(values: any[]) {
    this._values = values;
  }

  @Output() onRowClick = new EventEmitter<any>();
  @Output() onDownload = new EventEmitter<any>();

  downloadFile() {
    this.onDownload.emit(this._values);
  }

  columnClicked(column) {
    this.onRowClick.emit(column);
  }

  get values(): any[] {
    return this._values;
  }

  constructor() {}

  ngOnInit(): void {
    this.headerConfig_ = { ...this.headerConfig_, ...this.headerConfig };
  }

  get columnStyles(): any {
    return {
      'grid-template-columns': this.columnsConfig
        .map((config: ITableColumnConfig) => config.size)
        .join(' '),
    };
  }

  get columnStylesHeader(): any {
    return {
      'grid-template-columns':
        this.columnsConfig
          .map((config: ITableColumnConfig) => config.size)
          .join(' ') + ' 10px',
    };
  }

  get titleStyle(): any {
    return this.headerConfig_;
  }

  getData(item, keys: any) {
    if (typeof keys === 'object') {
      let res = item;
      for (let k of keys) {
        res = res[k];
      }
      return res;
    } else return item[keys];
  }

  getBooleanOpt(item, booleanopt) {
    let cssClass = '';
    if (item == booleanopt.trueValue) cssClass = 'booleanitemtrue';
    else if (item == booleanopt.falseValue) cssClass = 'booleanitemfalse';
    return cssClass;
  }

  // public setValues(values: any[]) {
  //   this.values = values;
  // }
}
