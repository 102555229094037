import { IBridge } from '../interfaces/bridgeinterface';

export class BridgeModel implements IBridge {
  public idMaquina?: string;
  public NumeroSerial?: string;
  public width?: string;
  public height?: string;
  public PosX?: string;
  public PosY?: string;
  public PosZ?: string;

  public static fromJson(js: IBridge): BridgeModel {
    try {
      let obj = new BridgeModel();
      obj.idMaquina = js.idMaquina;
      obj.NumeroSerial = js.NumeroSerial;
      obj.width = js.width;
      obj.height = js.height;
      obj.PosX = js.PosX;
      obj.PosY = js.PosY;
      obj.PosZ = js.PosZ;
      return obj;
    } catch (error) {
      console.log(
        '[ERROR] Erro ao implementar fromJson => BridgeModel => ',
        error
      );
      return new BridgeModel();
    }
  }

  public toJson(): IBridge {
    return {
      idMaquina: this.idMaquina,
      NumeroSerial: this.NumeroSerial,
      width: this.width,
      height: this.height,
      PosX: this.PosX,
      PosY: this.PosY,
      PosZ: this.PosZ,
    };
  }

  public toJsonCad(): IBridge {
    return {
      idMaquina: this.idMaquina,
      NumeroSerial: this.NumeroSerial,
      PosX: this.PosX,
      PosY: this.PosY,
    };
  }

  get name() {
    return `(${this.idMaquina}) Ponte - Serial: ${this.NumeroSerial}`;
  }

  get width_value() {
    return Number(this.width);
  }
  get height_value() {
    return Number(this.height);
  }
  get positionX_value() {
    return Number(this.PosX);
  }
  get positionY_value() {
    return Number(this.PosY);
  }
  get positionZ_value() {
    return Number(this.PosZ);
  }
}
