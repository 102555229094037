import { Injectable } from '@angular/core';
import { ConnectionService } from './connection.service';
import { ENDPOINTS } from './enpoints';
import { IInverter } from 'src/app/interfaces/inverterinterface';
import { InverterModel } from 'src/app/models/invertermodel';

@Injectable({
  providedIn: 'root',
})
export class InverterRepository {
  constructor(private conn: ConnectionService) {}

  async getAll(): Promise<IInverter[]> {
    try {
      return (await this.conn.get(ENDPOINTS().inverter)) as IInverter[];
    } catch (error) {
      console.error(error);
    }
  }

  async insert(item: InverterModel) {
    try {
      if (item.idInversora) {
        return await this.conn.put(ENDPOINTS().inverter, item.toJson());
      }
      return await this.conn.post(ENDPOINTS().inverter, item.toJson());
    } catch (error) {}
  }
}
