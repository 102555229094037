import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GatewayModel } from 'src/app/models/gatewaymodel';
import { GatewayRepository } from 'src/app/services/repository/gateway.repository';

@Component({
  selector: 'app-cadgateway',
  templateUrl: './cadgateway.component.html',
  styleUrls: ['./cadgateway.component.scss']
})
export class CadgatewayComponent {
  @Input() title: string = 'Cadastro de Gateway';
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GatewayModel,
    public dialogRef: MatDialogRef<CadgatewayComponent>,
    public repository: GatewayRepository
  ) {}

  async send() {
    this.loading = true;
    try {
      console.log('Item data => ', this.data.toJson());
      const res = await this.repository.insert(this.data);
      console.log('Insert Response => ', res);

      this.dialogRef.close(this.data);
    } catch (error) {}
    this.loading = false;
  }
}
