import { Injectable } from '@angular/core';
import { ConnectionService } from './connection.service';
import { ENDPOINTS } from './enpoints';
import { ILoadCell } from 'src/app/interfaces/loadcellinterface';
import { LoadCellModel } from 'src/app/models/loadcellmodel';

@Injectable({
  providedIn: 'root',
})
export class LoadCellsRepository {
  constructor(private conn: ConnectionService) {}

  async getAll(): Promise<ILoadCell[]> {
    try {
      return (await this.conn.get(ENDPOINTS().loadcells)) as ILoadCell[];
    } catch (error) {
      console.error(error);
    }
  }

  async insert(item: LoadCellModel) {
    try {
      if (item.idCelulaCarga) {
        return await this.conn.put(ENDPOINTS().loadcells, item.toJson());
      }
      return await this.conn.post(ENDPOINTS().loadcells, item.toJson());
    } catch (error) {}
  }
}
