import { Injectable } from '@angular/core';
import { ILevelUser } from 'src/app/interfaces/leveluserinterface';
import { getLevelByCode, LevelUser } from 'src/app/models/levelusermodel';
import { environment } from 'src/environments/environment';
import { ArrayInterfaceToArrayClass } from '../uteis/arraytoarrayclass';
import { UserModel } from 'src/app/models/usersmodel';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private _menu_open: boolean = false;
  private _user: UserModel = new UserModel();
  private _levels: LevelUser[] = [];

  constructor() {
    // if (!environment.production) {
    //   this._user.level = '999';
    //   this._user.user = 'Teste';
    //   this._user.name = 'Teste';
    //   this._user.pin = '115590';
    // }
  }

  get menuOpened(): boolean {
    return this._menu_open;
  }

  public changeMenuPosition() {
    this._menu_open = !this._menu_open;
  }

  set levels(levels: ILevelUser[]) {
    this._levels = ArrayInterfaceToArrayClass(levels, LevelUser);
  }

  get user(): UserModel {
    return this._user;
  }

  set user(userdata: UserModel) {
    this._user = userdata;
  }

  public clearUser() {
    this._user = new UserModel();
  }

  get isViewer() {
    // return Number(this.user.level) == 0;
    return false;
  }

  get isAdmin() {
    // return Number(this.user.level) == 999;
    return true;
  }

  get levelLoginName(): string {
    // return getLevelByCode(this.user.level, this._levels);
    return "Administrador"
  }
}
