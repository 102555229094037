import { ILoadCell } from '../interfaces/loadcellinterface';
import { BridgeModel } from './bridgemodel';

export class LoadCellModel implements ILoadCell {
  idCelulaCarga?: any;
  idMaquina?: any;
  Marca?: any;
  Modelo?: any;
  NumeroSerial?: any;
  EnderecoModbus?: any;

  public static fromJson(js: ILoadCell) {
    let obj = new LoadCellModel();
    obj.idCelulaCarga = js.idCelulaCarga;
    obj.idMaquina = js.idMaquina;
    obj.Marca = js.Marca;
    obj.Modelo = js.Modelo;
    obj.NumeroSerial = js.NumeroSerial;
    obj.EnderecoModbus = js.EnderecoModbus;
    return obj;
  }

  public toJson() {
    return {
      idCelulaCarga: this.idCelulaCarga,
      idMaquina: this.idMaquina,
      Marca: this.Marca,
      Modelo: this.Modelo,
      NumeroSerial: this.NumeroSerial,
      EnderecoModbus: this.EnderecoModbus,
    };
  }

  set selectBridge(item: BridgeModel) {
    console.log('Bridge Selecionada -> ', item);
    this.idMaquina = item.idMaquina;
  }
}
