import { Component, Input } from '@angular/core';

@Component({
  selector: 'labeltag',
  templateUrl: './labeltag.component.html',
  styleUrls: ['./labeltag.component.scss'],
})
export class LabeltagComponent {
  @Input() label: string = '';
  @Input() background: string = 'var(--color-toptable)';
  @Input() color: string = 'var(--color-secondary)';
  @Input() width: string = '270px';
  @Input() widthtag: string = '150px';
  @Input() widthlabel: string = '120px';

  get tagstyle() {
    return {
      border: `1px solid ${this.background}`,
      width: this.width,
      "grid-template-columns": `${this.widthtag} ${this.widthlabel}`
    };
  }

  get tagdatastyle() {
    return {
      background: this.background,
      color: this.color,
    };
  }

  get tagtextstyle() {
    return {
      color: this.background,
    };
  }
}
