import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BridgeModel } from 'src/app/models/bridgemodel';
import { BridgeRepository } from 'src/app/services/repository/bridge.repository';

@Component({
  selector: 'app-cadbridge',
  templateUrl: './cadbridge.component.html',
  styleUrls: ['./cadbridge.component.scss'],
})
export class CadbridgeComponent {
  @Input() title: string = 'Cadastro de Ponte Rolante';
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BridgeModel,
    public dialogRef: MatDialogRef<CadbridgeComponent>,
    private bridgerepo: BridgeRepository
  ) {}

  async send() {
    this.loading = true;
    try {
      console.log('Item data => ', this.data.toJsonCad());
      const res = await this.bridgerepo.insert(this.data);
      console.log('Insert Response => ', res);

      this.dialogRef.close(this.data);
    } catch (error) {}
    this.loading = false;
  }
}
