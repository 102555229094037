import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Observable } from 'rxjs';

@Injectable()
export class EventMqttService {

  constructor(private _mqttService: MqttService) {
  }

  topic(topic: string): Observable<IMqttMessage> {
    return this._mqttService.observe(topic);
  }
}
