import { ILevelUser } from '../interfaces/leveluserinterface';
import { UserModel } from './usersmodel';

export class LevelUser implements ILevelUser {
  description: string;
  levelInicial: number;
  levelFinal: number;

  public static fromJson(data: any) {
    let levelUser = new LevelUser();
    levelUser.description = data.description;
    levelUser.levelInicial = data.levelInicial;
    levelUser.levelFinal = data.levelFinal;
    return levelUser;
  }
}

export const getLevel = (user: UserModel, levels: LevelUser[]) => {
  try {
    // if (Number(user.level) > -1 && user.level != "")
    //   return (
    //     levels.find(
    //       (l) =>
    //         Number(user.level) >= Number(l.levelInicial) &&
    //         Number(user.level) <= Number(l.levelFinal)
    //     ).description + ` (${user.level})`
      // );
  } catch (error) {
    return null;
  }
};

export const getLevelByCode = (code:any, levels: LevelUser[]) => {
  try {
    if (Number(code) > -1 && code != "")
      return (
        levels.find(
          (l) =>
            Number(code) >= Number(l.levelInicial) &&
            Number(code) <= Number(l.levelFinal)
        ).description + ` (${code})`
      );
  } catch (error) {
    return null;
  }
};

export const setUsersLevel = (users: UserModel[], levels: LevelUser[]) => {
  return users.map((u) => {
    // u.dslevel = getLevel(u, levels);
    return u;
  });
};
