<main id="main">
  <div class="header">
    <div class="title">Gerenciamento de Pontes</div>
  </div>
  <div class="container">
    <div class="divisor">
      <div class="bridges" style="width: 100%">
        <backlog title="Vistas" *ngIf="!selectedbridge">
          <div class="selecterbridge">
            <backlog *ngFor="let bridge of bridges" [title]="bridge.name">
              <bridgedrawing
                (click)="selectedbridge = bridge"
                [bridge]="bridge"
                height="100%"
                width="100%"
                zoom="50%"
              ></bridgedrawing>
            </backlog>
          </div>
        </backlog>
        <backlog
          [title]="selectedbridge.name"
          *ngIf="selectedbridge"
          [icons]="[returnicon]"
          (onAction)="selectedbridge = null"
        >
          <div class="infos">
            <div class="tabinfos">
              <div
                class="tab"
                [ngClass]="{ tabselected: tab == 1 }"
                (click)="tab = 1"
              >
                Manutenção
              </div>
              <div
                class="tab"
                [ngClass]="{ tabselected: tab == 2 }"
                (click)="tab = 2"
              >
                Operação
              </div>
            </div>
            <div class="bodyinfos" *ngIf="tab == 1">
              <labeltag label="Peso">teste</labeltag>
              <labeltag label="Status">teste</labeltag>
              <labeltag label="Falhas">teste</labeltag>
              <labeltag label="Tempo em Operação">teste</labeltag>
              <labeltag label="Temperatura Motores">teste</labeltag>
              <labeltag label="Tensão Inversor">teste</labeltag>
              <labeltag label="Corrente Inversor">teste</labeltag>
              <labeltag label="Frequência Inversor">teste</labeltag>
              <labeltag label="Última Revisão">teste</labeltag>
              <labeltag label="Próxima Revisão">teste</labeltag>
            </div>
            <div class="bodyinfos" *ngIf="tab == 2">
              <labeltag label="Série">teste</labeltag>
              <labeltag label="Registro de Sobrepeso">teste</labeltag>
              <labeltag label="Registro de Alarmes">teste</labeltag>
              <labeltag label="Tempo em Operação">teste</labeltag>
            </div>
          </div>
          <bridgedrawing [bridge]="selectedbridge" height="calc(100vh - 346px)"></bridgedrawing>
        </backlog>
      </div>
      <div class="options" style="width: 550px">
        <div class="log">
          <backlog title="Logs">teste</backlog>
        </div>
        <div class="details">
          <backlog title="Detalhes">teste</backlog>
        </div>
      </div>
    </div>
  </div>
</main>
<menu #menu></menu>
<!-- <spinner *ngIf="loading">Carregando...</spinner> -->
