import { Component, OnInit } from '@angular/core';
import {
  ITableColumnConfig,
  ITableHeaderConfig,
} from 'src/app/components/tablecomponent/tablecomponent.constants';
import { CadbridgeComponent } from 'src/app/modals/cadbridge/cadbridge.component';
import { BridgeModel } from 'src/app/models/bridgemodel';
import { ModalService } from 'src/app/services/modalservice/modalservice.service';
import { BridgeRepository } from 'src/app/services/repository/bridge.repository';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';

@Component({
  selector: 'app-bridges',
  templateUrl: './bridges.component.html',
  styleUrls: ['./bridges.component.scss'],
})
export class BridgesComponent implements OnInit {
  public loading: boolean = false;
  public list: BridgeModel[] = [];

  /**
   * Configurações de table
   */
  public configTable: ITableColumnConfig[] = [
    {
      key: 'idMaquina',
      label: 'ID',
      size: '100px',
      columnsize: 7.5,
      alignment: 'center',
    },
    { key: 'NumeroSerial', label: 'Serial', size: '1fr', columnsize: 40 },
  ];

  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  constructor(
    private bridgeRepo: BridgeRepository,
    private modal: ModalService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  public async init() {
    this.loading = true;
    try {
      this.list = ArrayInterfaceToArrayClass(
        await this.bridgeRepo.getAll(),
        BridgeModel
      );
      console.log('[INFO] Listagem de bridges => ', this.list);
    } catch (error) {
      console.log('[ERROR] Init => ', error);
    }
    this.loading = false;
  }

  public async new(data: BridgeModel = new BridgeModel()) {
    try {
      this.modal
        .show(CadbridgeComponent, {
          height: 'auto',
          width: '566px',
          data: data,
        })
        .then((_) => {
          this.init();
        });
    } catch (error) {}
  }
}
