import { Component, OnInit } from '@angular/core';
import { ITableColumnConfig, ITableHeaderConfig } from 'src/app/components/tablecomponent/tablecomponent.constants';
import { DeviceModel } from 'src/app/models/devicesmodel';
import { DeviceRepository } from 'src/app/services/repository/device.repository';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  public loading: boolean = false;
  public list: DeviceModel[] = [];

  /**
   * Configurações de table
   */
  public configTable: ITableColumnConfig[] = [
    {
      key: 'idDispositivo',
      label: 'ID',
      size: '100px',
      columnsize: 7.5,
      alignment: 'center',
    },
    { key: 'Marca', label: 'Marca', size: '1fr', columnsize: 40 },
    { key: 'Modelo', label: 'Modelo', size: '1fr', columnsize: 40 },
    { key: 'Tipo', label: 'Tipo', size: '1fr', columnsize: 40 },
    { key: 'NumeroSerial', label: 'Serial', size: '1fr', columnsize: 40 },
  ];

  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  constructor(private devicerepo: DeviceRepository) {}

  ngOnInit(): void {
    this.init();
  }

  public async init() {
    this.loading = true;
    try {
      this.list = ArrayInterfaceToArrayClass(
        await this.devicerepo.getAll(),
        DeviceModel
      );
      console.log('[INFO] Listagem de devices => ', this.list);
    } catch (error) {
      console.log('[ERROR] Init => ', error);
    }
    this.loading = false;
  }

  public async new() {}
}
