import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() height = "50px"
  @Input() width = "50px"
  @Input() left = "0"
  @Input() right = "0"
  @Input() bottom = "0"
  @Input() top = "0"
  @Input() icon = ""

  constructor() { }

  ngOnInit(): void {
  }

}
