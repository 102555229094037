import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Observable, Subscription } from 'rxjs';

export class MessageMqtt {
  private _message: any;
  public topic: any;

  set message(data: any) {
    try {
      this._message = JSON.parse(data);
    } catch {
      this._message = data;
    }
  }

  get message() {
    return this._message;
  }

  public static fromPayload(data: IMqttMessage, topic: any) {
    let obj = new MessageMqtt();
    obj.message = data.payload.toString();
    obj.topic = topic;
    return obj;
  }
}

@Injectable()
export class MqttClientService {
  public subscription: Subscription;

  constructor(
    private _mqttService: MqttService
  ) {}

  public subscribeToTopic(topic: string): Observable<any> {
    return new Observable((subscriber) => {
      this.subscription = this._mqttService
        .observe(topic)
        .subscribe((data: IMqttMessage) => {
          subscriber.next(MessageMqtt.fromPayload(data, topic));
        });
    });
  }

  public clearSubscribe() {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
