<section id="icon">
    <div
        class="icon"
        [style.background]="'url(' + icon + ')'"
        [style.width]="width"
        [style.height]="height"
        [style.marginLeft]="left"
        [style.marginRight]="right"
        [style.marginBottom]="bottom"
        [style.marginTop]="top"
    ></div>
</section>
