import { Injectable } from '@angular/core';
import { IBridge } from 'src/app/interfaces/bridgeinterface';
import { ConnectionService } from './connection.service';
import { ENDPOINTS } from './enpoints';
import { BridgeModel } from 'src/app/models/bridgemodel';

@Injectable({
  providedIn: 'root',
})
export class BridgeRepository {
  constructor(private conn: ConnectionService) {}

  async getAll(): Promise<IBridge[]> {
    try {
      const res = await this.conn.get(ENDPOINTS().bridges);
      return res as IBridge[];
    } catch (error) {
      console.error(error);
    }
  }

  async insert(bridge: BridgeModel) {
    try {
      if (bridge.idMaquina) {
        return await this.conn.put(ENDPOINTS().bridges, bridge.toJsonCad());
      }
      return await this.conn.post(ENDPOINTS().bridges, bridge.toJsonCad());
    } catch (error) {}
  }
}
