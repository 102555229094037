import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'billboard',
  templateUrl: './billboard.component.html',
  styleUrls: ['./billboard.component.scss'],
})
export class BillboardComponent implements OnInit {
  @Input() title = '';
  @Input() height = '100%';
  @Input() width = '100%';

  constructor() {}

  ngOnInit(): void {}

  get styleData() {
    return {
      height: this.height,
      width: this.width,
    };
  }
}
