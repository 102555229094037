<div id="backlog" #backlog [ngStyle]="styles">
  <div class="backlogheader">
    <div class="backlogheaderlabel">{{ title }}</div>
    <div
      class="iconbacklog"
      *ngFor="let icon of icons"
      [style.background]="getBackground(icon)"
      (click)="handleIconClicked(icon)"
    ></div>
  </div>
  <div class="backlogbody"><ng-content></ng-content></div>
</div>
