import { IDevice } from '../interfaces/deviceinterface';

export class DeviceModel implements IDevice {
  idDispositivo?: string;
  idGateway?: string;
  Marca?: string;
  Modelo?: string;
  NumeroSerial?: string;
  Tipo?: string;

  public static fromJson(js: IDevice) {
    try {
      let obj = new DeviceModel();
      obj.idDispositivo = js.idDispositivo;
      obj.idGateway = js.idGateway;
      obj.Marca = js.Marca;
      obj.Modelo = js.Modelo;
      obj.NumeroSerial = js.NumeroSerial;
      obj.Tipo = js.Tipo;
      return obj;
    } catch (error) {
      console.log(
        '[ERROR] Erro ao implementar fromJson => DeviceModel => ',
        error
      );
      return new DeviceModel();
    }
  }

  public toJson() {
    return {
      idDispositivo: this.idDispositivo,
      idGateway: this.idGateway,
      Marca: this.Marca,
      Modelo: this.Modelo,
      NumeroSerial: this.NumeroSerial,
      Tipo: this.Tipo,
    };
  }
}
