import { Injectable } from '@angular/core';
import { ConnectionService } from './connection.service';
import { ENDPOINTS } from './enpoints';
import { IUser } from 'src/app/interfaces/usersinterface';
import { UserModel } from 'src/app/models/usersmodel';

@Injectable({
  providedIn: 'root',
})
export class UsersRepository {
  constructor(private conn: ConnectionService) {}

  async getAll(): Promise<IUser[]> {
    try {
      return (await this.conn.get(ENDPOINTS().user)) as IUser[];
    } catch (error) {
      console.error(error);
    }
  }

  async insert(item: UserModel) {
    try {
      if (item.idUsuario) {
        return await this.conn.put(ENDPOINTS().cadUser, item.toJson());
      }
      return await this.conn.post(ENDPOINTS().cadUser, item.toJson());
    } catch (error) {
      console.error(error);
    }
  }

  async login(user: UserModel) {
    return await this.conn.post(ENDPOINTS().login, user.loginJson());
  }
}
