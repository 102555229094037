import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BridgeModel } from 'src/app/models/bridgemodel';
import { InverterModel } from 'src/app/models/invertermodel';
import { BridgeRepository } from 'src/app/services/repository/bridge.repository';
import { InverterRepository } from 'src/app/services/repository/inverterrepository';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';

@Component({
  selector: 'app-cadinverter',
  templateUrl: './cadinverter.component.html',
  styleUrls: ['./cadinverter.component.scss'],
})
export class CadinverterComponent implements OnInit{
  @Input() title: string = 'Cadastro de Inversora';
  public bridges: BridgeModel[] = [];
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InverterModel,
    public dialogRef: MatDialogRef<CadinverterComponent>,
    public repository: InverterRepository,
    public bridgerepo: BridgeRepository
  ) {}

  ngOnInit() {
    this.getBridges();
  }

  async getBridges() {
    this.loading = true;
    try {
      this.bridges = ArrayInterfaceToArrayClass(
        await this.bridgerepo.getAll(),
        BridgeModel
      );
    } catch (error) {}
    this.loading = false;
  }

  async send() {
    this.loading = true;
    try {
      console.log('Item data => ', this.data.toJson());
      const res = await this.repository.insert(this.data);
      console.log('Insert Response => ', res);

      this.dialogRef.close(this.data);
    } catch (error) {}
    this.loading = false;
  }
}
