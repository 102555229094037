import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'selectcomponent',
  templateUrl: './selectcomponent.component.html',
  styleUrls: ['./selectcomponent.component.scss'],
})
export class SelectcomponentComponent implements OnInit {
  @Input() margin: string = '2px';
  @Input() hinttext: string;
  @Input() labelkey: string = 'label';
  @Input() idkey: string = 'id';
  @Input() disabled: boolean = false;
  private _pattern: any;
  private _data: any[];
  @Output() dataChange = new EventEmitter<string>();
  @Output() selectedChange = new EventEmitter<string>();
  public focused = false;
  public showoptions = false;
  public value: string;

  @Input() set data(value: any[]) {
    this._data = value;
    this.setPattern();
  }

  @Input() set pattern(value: any) {
    this._pattern = value;
    this.setPattern();
  }

  get pattern(): any {
    return this._pattern;
  }

  get data(): any[] {
    return this._data;
  }

  constructor(private eRef: ElementRef) {}

  ngOnInit(): void {}

  public setPattern() {
    try {
      if (this._data && this.pattern) {
        let res = this._data.find((item) => item[this.idkey] == this.pattern);
        this.value = `${res[this.labelkey]} (${res[this.idkey]})`;
      }
    } catch (error) {
      // console.log("Erro=> ", error);
      
    }
  }

  public onChange() {
    // this.dataChange.emit(this.data);
  }

  public onSelect(item: any) {
    this.selectedChange.emit(item);
    this.value = `${item[this.labelkey]} (${item[this.idkey]})`;
    this.showoptions = false;
  }

  @HostListener('document:click', ['$event'])
  public lostFocus(evt) {
    this.showoptions = this.eRef.nativeElement.contains(evt.target);
  }
}
