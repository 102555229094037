import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(private http: HttpClient) {}

  async get(endpoint: string) {
    try {
      return await this.http.get(endpoint).toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async post(endpoint: string, body: any) {
    try {
      return await this.http.post(endpoint, body).toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async put(endpoint: string, body: any) {
    try {
      return await this.http.put(endpoint, body).toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async delete(endpoint: string) {
    try {
      return await this.http.delete(endpoint).toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
