<div
  id="inputcomponent"
  [style.marginBottom]="marginBottom"
  [style.marginTop]="marginTop"
  [style.marginLeft]="marginLeft"
  [style.marginRight]="marginRight"
>
  <input
    #inputer
    [(ngModel)]="data"
    (keyup)="onChange()"
    (keyup.enter)="onEnterPressed()"
    (change)="onChange()"
    (focus)="focused = true"
    (blur)="focused = false"
    [type]="type"
    [autocomplete]="autocomplete"
    [disabled]="disabled"
  />
  <div class="clear" *ngIf="clearbutton" (click)="onClear()"></div>
  <label
    class="inputcomponenthittext"
    *ngIf="hinttext"
    [ngClass]="{ inputcomponenthittextfocused: focused || data}"
    (click)="setFocus()"
    >{{ hinttext }}</label
  >
  <div class="errorinputcomponent" *ngIf="error">{{ error }}</div>
</div>
