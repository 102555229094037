type background = 'danger' | 'success' | 'warning';

const colors = {
  danger: '#9c0217',
  warning: '#e57104',
  success: '#049d02',
};
/**
 * Cria um alerta que, por padrão, é um alerta de erro (vermelho)
 * @param text texto do alerta
 * @param background cor do background do alerta
 * @param color cor do texto do alerta
 * @time tempo que o alerto permanece em tela
 */
export const sendAlert = (
  text: string,
  backgroundColor: background = 'danger',
  color = 'white',
  time = 3000
) => {
  let aviso: HTMLElement = document.createElement('div');
  aviso.innerHTML = text;

  let estilo = aviso.style;
  estilo.backgroundColor = colors[backgroundColor];
  estilo.color = color;
  estilo.transition = 'bottom 0.8s';
  estilo.bottom = '-60px';
  estilo.left = '0';
  estilo.right = '0';
  estilo.margin = '0 auto';
  estilo.position = 'fixed';
  estilo.zIndex = '99999999';
  estilo.width = '33vw';
  estilo.padding = '12px';
  estilo.textAlign = 'center';
  estilo.borderRadius = '5px';

  setTimeout(() => {
    estilo.bottom = '20px';
  }, 100);

  setTimeout(() => {
    estilo.bottom = '-60px';
    setTimeout(() => {
      if (aviso.parentElement) aviso.parentElement.removeChild(aviso);
    }, 800);
  }, time);

  document.body.appendChild(aviso);
};
