import { IInverter } from '../interfaces/inverterinterface';
import { BridgeModel } from './bridgemodel';

export class InverterModel implements IInverter {
  idInversora?: Number;
  idMaquina?: any;
  dsMaquina?: Number;
  Marca?: string;
  Modelo?: string;
  NumeroSerial?: string;
  EnderecoModbus?: string;

  public static fromJson(js: IInverter) {
    let obj = new InverterModel();
    obj.idInversora = js.idInversora;
    obj.idMaquina = js.idMaquina;
    obj.dsMaquina = js.idMaquina;
    obj.Marca = js.Marca;
    obj.Modelo = js.Modelo;
    obj.NumeroSerial = js.NumeroSerial;
    obj.EnderecoModbus = js.EnderecoModbus;
    return obj;
  }

  public toJson() {
    return {
      idInversora: this.idInversora,
      idMaquina: this.idMaquina,
      Marca: this.Marca,
      Modelo: this.Modelo,
      NumeroSerial: this.NumeroSerial,
      EnderecoModbus: this.EnderecoModbus,
    };
  }

  set selectBridge(item: BridgeModel) {
    console.log('Bridge Selecionada -> ', item);
    this.idMaquina = item.idMaquina;
  }
}
