import { IGateway } from '../interfaces/gatewayinterface';

export class GatewayModel implements IGateway {
  public idGateway?: string;
  public Marca?: string;
  public Modelo?: string;
  public ModbusAdd?: string;
  public ModbusID?: string;
  public Tipo?: string;
  public NumeroSerial?: string;

  public static fromJson(js: IGateway) {
    try {
      let obj = new GatewayModel();
      obj.idGateway = js.idGateway;
      obj.Marca = js.Marca;
      obj.Modelo = js.Modelo;
      obj.ModbusAdd = js.ModbusAdd;
      obj.ModbusID = js.ModbusID;
      obj.Tipo = js.Tipo;
      obj.NumeroSerial = js.NumeroSerial;
      return obj;
    } catch (error) {
      console.log(
        '[ERROR] Erro ao implementar fromJson => GatewayModel => ',
        error
      );
      return new GatewayModel();
    }
    
  }

  public toJson() {
    return {
      idGateway: this.idGateway,
      Marca: this.Marca,
      Modelo: this.Modelo,
      ModbusAdd: this.ModbusAdd,
      ModbusID: this.ModbusID,
      Tipo: this.Tipo,
      NumeroSerial: this.NumeroSerial,
    };
  }
}
