import { environment } from 'src/environments/environment';

export const ENDPOINTS = () => {
  if (environment.production)
    return {
      login: `${environment.server}/login`,
      user: `${environment.server}/user`,
      cadUser: `${environment.server}/cadastraUsuario`,
      revisao: `${environment.server}/revisao`,
      bridges: `${environment.server}/maquina`,
      gateways: `${environment.server}/gateway`,
      devices: `${environment.server}/inversora`,
      inverter: `${environment.server}/inversora`,
      loadcells: `${environment.server}/CelulaCarga`,
      relatorio: `${environment.server}/relatorio`,
    };
  else
    return {
      // bridges: `${environment.server}/bridges.json`,
      // gateways: `${environment.server}/gateways.json`,
      // devices: `${environment.server}/devices.json`,
      login: `${environment.server}/login`,
      user: `${environment.server}/user`,
      cadUser: `${environment.server}/cadastraUsuario`,
      revisao: `${environment.server}/revisao`,
      bridges: `${environment.server}/maquina`,
      gateways: `${environment.server}/gateway`,
      devices: `${environment.server}/inversora`,
      inverter: `${environment.server}/inversora`,
      loadcells: `${environment.server}/CelulaCarga`,
      relatorio: `${environment.server}/relatorio`,
    };
};
