import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  private _data: boolean = true;
  @Output() onChange = new EventEmitter<boolean>();

  @Input()
  set data(data: boolean) {
    this._data = data;
  }

  get data() {
    return this._data;
  }

  constructor() {}

  ngOnInit(): void {}

  reverse() {
    this.data = !this.data;
    this.onChange.emit(this.data);
  }

  get styleclass() {
    return this.data
      ? { 'background-color': 'rgb(69, 228, 90)' }
      : { 'background-color': 'rgb(228, 69, 69)' };
  }

  get spamclass() {
    return this.data
      ? {
          left: 0,
        }
      : {
          left: '16px',
        };
  }
}
