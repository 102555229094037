import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IBacklogIcon } from './interfaces/backlogicons.interface';

@Component({
  selector: 'backlog',
  templateUrl: './backlog.component.html',
  styleUrls: ['./backlog.component.scss'],
})
export class BacklogComponent implements OnInit {
  @Input() title = 'Backlog';
  @Input() height = '100%';
  @Input() width = '100%';
  @Input() icons: IBacklogIcon[] = [];

  @Output() onAction = new EventEmitter<IBacklogIcon>();

  constructor() {}

  ngOnInit(): void {}

  get styles() {
    return {
      width: this.width,
      height: this.height,
    };
  }

  handleIconClicked(icon: IBacklogIcon) {
    console.log('[BAKCLOG] Icon clicked => ', icon);
    this.onAction.emit(icon);
  }

  getBackground(icon: IBacklogIcon) {
    return `url("${icon.icon}")`;
  }
}
