<div id="login">
  <div class="containerlogin">
    <div class="logo"></div>
    <div class="form">
      <div class="inputlogin">
        <div class="label">Usuário</div>
        <input type="text" [(ngModel)]="user.Usuario" />
      </div>
      <div class="inputlogin">
        <div class="label">Senha</div>
        <input type="password" [(ngModel)]="user.Senha" (keyup.enter)="login()"/>
      </div>
    </div>
    <div class="buttonlogin" (click)="login()">Entrar</div>
  </div>
  <div class="backlogin">
    <video [autoplay]="true" [muted]="true" [loop]="true" #videodata id="video">
      <source src="assets/images/site.mp4" type="video/mp4" />
    </video>
  </div>
</div>
<spinner *ngIf="loading">Entrando...</spinner>
