<section id="modal">
  <div class="headermodal">
    <div class="titlemodal">{{ title }}</div>
    <icon
      icon="/assets/icons/closeicon.svg"
      width="20px"
      height="20px"
      class="iconmodal"
      mat-dialog-close
    ></icon>
  </div>
  <div class="bodymodal">
    <div class="formdata">
      <inputcomponent
        hinttext="ID"
        marginBottom="4px"
        [(data)]="data.idMaquina"
        type="text"
        style="width: 20%"
        disabled="true"
      ></inputcomponent>
      <inputcomponent
        hinttext="Número Serial"
        marginBottom="4px"
        [(data)]="data.NumeroSerial"
        type="text"
        style="width: 80%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Posição em X"
        marginBottom="4px"
        [(data)]="data.PosX"
        type="number"
        style="width: 50%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Posição em Y"
        marginBottom="4px"
        [(data)]="data.PosY"
        type="number"
        style="width: 50%"
      ></inputcomponent>
    </div>
    <div style="float: right; margin-top: 12px;">
      <buttoncomponent
        text="Salvar"
        background="var(--color-primary)"
        (onClick)="send()"
      ></buttoncomponent>
    </div>
  </div>
  <spinner *ngIf="loading">Carregando...</spinner>
</section>
