<main id="main">
    <div class="header">
      <div class="title">Inversoras</div>
      <div class="menuicon" (click)="new()">
        <icon icon="assets/icons/addnew.svg" width="32px" height="32px"></icon>
      </div>
    </div>
    <div class="container">
        <tablecomponent
        #tableComponent
        [columnsConfig]="configTable"
        [values]="list"
        [headerConfig]="TableHeaderConfig"
        [download]="false"
        title="Lista de Inversoras Cadastradas"
        *ngIf="!loading"
        height="calc(100vh - 80px)"
        (onRowClick)="new($event)"
        ></tablecomponent>
    </div>
  </main>
  <menu #menu></menu>
  <spinner *ngIf="loading">Carregando...</spinner>
  