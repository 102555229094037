import { IMenuoption } from '../interfaces/menuinterface';
import { BridgesComponent } from '../pages/bridges/bridges.component';
import { DevicesComponent } from '../pages/devices/devices.component';
import { GatewaysComponent } from '../pages/gateways/gateways.component';
import { HomeComponent } from '../pages/home/home.component';
import { InvertersComponent } from '../pages/inverters/inverters.component';
import { LoadcellsComponent } from '../pages/loadcells/loadcells.component';
import { LoginComponent } from '../pages/login/login.component';
import { UsersComponent } from '../pages/users/users.component';

export const MENU_OPTIONS: IMenuoption[] = [
  {
    path: '',
    component: LoginComponent,
    showinmenu: false,
    levelAccess: 0,
  },
  {
    path: 'home',
    icon: 'assets/icons/dashboard.png',
    component: HomeComponent,
    label: 'Central',
    showinmenu: true,
    levelAccess: 0,
  },
  {
    icon: 'assets/icons/logs.svg',
    label: 'Logs',
    showinmenu: true,
    levelAccess: 0,
    submenu: [
      {
        icon: 'assets/icons/bridgelog.svg',
        path: 'reports',
        label: 'Log de Pontes',
        showinmenu: true,
        levelAccess: 0,
      },
      {
        icon: 'assets/icons/gatewayslog.svg',
        path: 'reports',
        label: 'Log de Gateways',
        showinmenu: true,
        levelAccess: 0,
      },
      {
        icon: 'assets/icons/logdevices.svg',
        path: 'reports',
        label: 'Log de Dispositivos',
        showinmenu: true,
        levelAccess: 0,
      },
    ],
  },
  {
    icon: 'assets/icons/report.png',
    label: 'Relatórios',
    showinmenu: true,
    levelAccess: 0,
    submenu: [
      {
        icon: 'assets/icons/reportoccurrences.svg',
        path: 'reports',
        label: 'Ocorrências',
        showinmenu: true,
        levelAccess: 0,
      },
    ],
  },
  {
    icon: 'assets/icons/manage.png',
    label: 'Gerenciamento',
    showinmenu: true,
    levelAccess: 999,
    submenu: [
      {
        icon: 'assets/icons/bridge.svg',
        path: 'bridge',
        component: BridgesComponent,
        label: 'Pontes Rolantes',
        showinmenu: true,
        levelAccess: 999,
      },
      {
        icon: 'assets/icons/switch.svg',
        label: 'Gateways',
        component: GatewaysComponent,
        showinmenu: true,
        levelAccess: 999,
        path: 'buttons',
      },
      // {
      //   icon: 'assets/icons/lines.svg',
      //   label: 'Dispositivos',
      //   component: DevicesComponent,
      //   showinmenu: true,
      //   levelAccess: 999,
      //   path: 'alerts',
      // },
      {
        icon: 'assets/icons/inverter.svg',
        label: 'Inversoras',
        component: InvertersComponent,
        showinmenu: true,
        levelAccess: 999,
        path: 'inverters',
      },
      {
        icon: 'assets/icons/loadcell.svg',
        label: 'Células de Carga',
        component: LoadcellsComponent,
        showinmenu: true,
        levelAccess: 999,
        path: 'loadcells',
      },
      {
        path: 'users',
        icon: 'assets/icons/user.svg',
        label: 'Usuários',
        component: UsersComponent,
        showinmenu: true,
        levelAccess: 999,
      },
      // {
      //   icon: 'assets/icons/sector.svg',
      //   label: 'Setores',
      //   showinmenu: true,
      //   levelAccess: 999,
      //   path: 'sectors',
      // },
      // {
      //   icon: 'assets/icons/break.svg',
      //   label: 'Motivos de Parada',
      //   showinmenu: true,
      //   levelAccess: 999,
      //   path: 'reasons',
      // },
    ],
    closed: true,
  },
  // {
  //   path: 'about',
  //   icon: 'assets/icons/info.png',
  //   label: 'Sobre',
  //   showinmenu: true,
  //   levelAccess: 0,
  // },
  {
    icon: 'assets/icons/out.svg',
    label: 'Sair',
    showinmenu: true,
    levelAccess: 0,
    exit:true,
  },
  // {
  //   label: 'Mobile',
  //   path: "mobile",
  //   showinmenu: false,
  //   levelAccess: 0,
  //   exit:true,
  // },
];

export const getRoutes = (): IMenuoption[] => {
  let menu_routes = MENU_OPTIONS.filter((item) => item.component);
  MENU_OPTIONS.forEach((item) => {
    if (item.submenu) {
      item.submenu.forEach((submenu) => {
        if (submenu.component) menu_routes.push(submenu);
      });
    }
  });
  return menu_routes;
};
