<section id="modal">
    <div class="headermodal">
      <div class="titlemodal">{{ title }}</div>
      <icon
        icon="/assets/icons/closeicon.svg"
        width="20px"
        height="20px"
        class="iconmodal"
        mat-dialog-close
      ></icon>
    </div>
    <div class="bodymodal">
      <div class="formdata">
        <inputcomponent
          hinttext="ID"
          marginBottom="4px"
          [(data)]="data.idGateway"
          type="text"
          style="width: 20%"
          disabled="true"
        ></inputcomponent>
        <inputcomponent
          hinttext="Número Serial"
          marginBottom="4px"
          [(data)]="data.NumeroSerial"
          type="text"
          style="width: 80%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Marca"
          marginBottom="4px"
          [(data)]="data.Marca"
          type="text"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Modelo"
          marginBottom="4px"
          [(data)]="data.Modelo"
          type="text"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Modbus ID"
          marginBottom="4px"
          [(data)]="data.ModbusID"
          type="text"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Modbus"
          marginBottom="4px"
          [(data)]="data.ModbusAdd"
          type="text"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Tipo"
          marginBottom="4px"
          [(data)]="data.Tipo"
          type="text"
          style="width: 100%"
        ></inputcomponent>
      </div>
      <div style="float: right; margin-top: 12px;">
        <buttoncomponent
          text="Salvar"
          background="var(--color-primary)"
          (onClick)="send()"
        ></buttoncomponent>
      </div>
    </div>
    <spinner *ngIf="loading">Carregando...</spinner>
  </section>
  