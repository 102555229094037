import { Component, OnInit } from '@angular/core';
import { IBacklogIcon } from 'src/app/components/backlog/interfaces/backlogicons.interface';
import { BridgeModel } from 'src/app/models/bridgemodel';
import { MqttClientService } from 'src/app/services/mqtt/mqtt.service';
import { BridgeRepository } from 'src/app/services/repository/bridge.repository';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public loading: boolean = false;
  public bridges: BridgeModel[] = [];
  public selectedbridge: BridgeModel = null;
  public tab: number = 1;

  public returnicon: IBacklogIcon = {
    icon: '/assets/icons/bridgesselect.svg',
    role: 'return',
  };

  constructor(
    private mqttclient: MqttClientService,
    private bridgeRepo: BridgeRepository
  ) {}

  ngOnInit(): void {
    this.init();
    this.mqttclient.subscribeToTopic('teste').subscribe((values) => {
      console.log('[MQTT] Receiveds => ', values);
    });
  }

  async init() {
    this.loading = true;
    try {
      this.bridges = ArrayInterfaceToArrayClass(
        await this.bridgeRepo.getAll(),
        BridgeModel
      );
      console.log('[INFO] Listagem de bridges => ', this.bridges);
    } catch (error) {}
    this.loading = false;
  }

  ngOnDestroy() {
    this.mqttclient.clearSubscribe();
  }
}
