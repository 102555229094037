<section id="menu" *ngIf="!isViewer">
  <div
    #top
    class="top"
    [style.background]="
      store.menuOpened
        ? 'url(' + backgroundOpen + ')'
        : 'url(' + backgroundClose + ')'
    "
  ></div>
  <div class="topcollapsed"></div>

  <div class="options">
    <div
      class="itemoption"
      *ngFor="let option of menuoptions"
      [matTooltip]="option.label"
      matTooltipPosition="left"
      [matTooltipDisabled]="store.menuOpened"
    >
      <div
        class="option"
        [ngClass]="{ optionactive: isRoute(option) }"
        (click)="go(option)"
      >
        <div
          class="iconoption"
          [style.background]="'url(' + option.icon + ')'"
        ></div>
        <div class="textoption" *ngIf="store.menuOpened">
          {{ option.label }}
        </div>
      </div>

      <!-- SUBMENUS -->
      <div
        class="submenu"
        *ngIf="option.submenu"
        [ngStyle]="{ height: option.closed ? '0px' : sizeMenu(option.submenu) }"
      >
        <div
          class="option"
          [ngClass]="{ optionactive: isRoute(suboption) }"
          (click)="go(suboption)"
          *ngFor="let suboption of option.submenu"
        >
          <div
            class="iconoption"
            [style.background]="'url(' + suboption.icon + ')'"
          ></div>
          <div class="textoption" *ngIf="store.menuOpened">
            {{ suboption.label }}
          </div>
        </div>
      </div>
      <!-- SUBMENUS FIM -->
    </div>
  </div>
  <div class="user" #user>
    <div class="avatar"></div>
    <div class="name">
      <div class="nametitle">{{userName}}</div>
      <div class="nametype">{{levelName}}</div>
    </div>
    <!-- <div class="openicon"></div> -->
  </div>
  <div class="close" (click)="change()">
    <div class="iconclose" *ngIf="store.menuOpened"></div>
    <div class="iconopen" *ngIf="!store.menuOpened"></div>
    <div class="labelclose" *ngIf="store.menuOpened">Fechar Menu</div>
  </div>
  <!-- <div class="collapse" (click)="change()">
    <div class="backcollapse" #collapse></div>
  </div> -->
</section>
