import { IUser } from '../interfaces/usersinterface';

export class UserModel implements IUser {
  idUsuario: string;
  Usuario: string;
  Nome: string;
  Funcao: string;
  Hash: string;
  Ativo: string;
  Senha: string;

  public static fromJson(data: any) {
    let user = new UserModel();
    user.Ativo = data.Ativo;
    user.idUsuario = data.idUsuario;
    user.Funcao = data.Funcao;
    user.Nome = data.Nome;
    user.Usuario = data.Usuario;
    user.Senha = data.Senha;
    user.Hash = data.Hash;
    return user;
  }

  public toJson() {
    return {
      idUsuario: this.idUsuario,
      Usuario: this.Usuario,
      Nome: this.Nome,
      Funcao: this.Funcao,
      Hash: this.Hash,
      Ativo: this.Ativo,
      Senha: this.Senha,
    };
  }

  public loginJson() {
    return {
      Usuario: this.Usuario,
      Senha: this.Senha,
    };
  }

  public cadJson() {
    return {
      Usuario: this.Usuario,
      Nome: this.Nome,
      Funcao: this.Funcao,
      Senha: this.Senha,
    };
  }

  get isAtivo() {
    return this.Ativo != 'Inativo';
  }

  set isAtivo(ativo: boolean) {
    console.log("Usuário ativo => ", ativo);
    
    this.Ativo = ativo ? '' : 'Inativo';
  }
}
