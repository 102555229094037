<section id="modal">
    <div class="headermodal">
      <div class="titlemodal">{{ title }}</div>
      <icon
        icon="/assets/icons/closeicon.svg"
        width="20px"
        height="20px"
        class="iconmodal"
        mat-dialog-close
      ></icon>
    </div>
    <div class="bodymodal">
      <div class="formdata">
        <inputcomponent
          hinttext="ID"
          marginBottom="4px"
          [(data)]="data.idInversora"
          type="text"
          style="width: 20%"
          disabled="true"
        ></inputcomponent>
        <selectcomponent
        hinttext="Selecionar Máquina"
        magin="12px"
        (selectedChange)="data.selectBridge = $event"
        [(data)]="bridges"
        [pattern]="data.idMaquina"
        labelkey="NumeroSerial"
        idkey="idMaquina"
        style="width: 80%"
        ></selectcomponent>
        <inputcomponent
          hinttext="Marca"
          marginBottom="4px"
          [(data)]="data.Marca"
          type="text"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Modelo"
          marginBottom="4px"
          [(data)]="data.Modelo"
          type="text"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Número Serial"
          marginBottom="4px"
          [(data)]="data.NumeroSerial"
          type="text"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Modbus"
          marginBottom="4px"
          [(data)]="data.EnderecoModbus"
          type="text"
          style="width: 50%"
        ></inputcomponent>
      </div>
      <div style="float: right; margin-top: 12px;">
        <buttoncomponent
          text="Salvar"
          background="var(--color-primary)"
          (onClick)="send()"
        ></buttoncomponent>
      </div>
    </div>
  </section>
  <spinner *ngIf="loading">Carregando...</spinner>
  