<div id="tablecomponent" [ngStyle]="{ height: height }">
  <div class="tablestruct">
    <div class="titletablecomponent" [ngStyle]="headerConfig_" *ngIf="title">
      <div class="texttitletablecomponent">{{ title }}</div>
      <div
        class="icontitletabelcomponent"
        (click)="downloadFile()"
        *ngIf="download"
      ></div>
    </div>
    <div class="toptablestruct" [ngStyle]="columnStylesHeader">
      <div
        class="columntoptablestruct"
        *ngFor="let item of columnsConfig"
        [ngStyle]="headerConfig_"
        [style.justifyContent]="item.alignment ? item.alignment : 'left'"
      >
        <div class="labelcolumntitle">{{ item.label }}</div>
      </div>
      <div class="columntoptablestruct" [ngStyle]="headerConfig_"></div>
    </div>
    <div
      class="bodytablebody"
      [ngStyle]="{ 'max-height': 'calc(' + height + ' - 70px)' }"
    >
      <div
        class="bodytablestruct"
        *ngFor="let item of values"
        [ngStyle]="columnStyles"
        (click)="columnClicked(item)"
      >
        <div
          class="columnbodytablestruct"
          *ngFor="let config of columnsConfig"
          [style.justifyContent]="config.alignment ? config.alignment : 'left'"
        >
          <div
            class="labelcolumnitem"
            *ngIf="!config.signal && !config.booleanOption"
          >
            {{ getData(item, config.key) }}
          </div>
          <div
            class="labelcolumnitem signalitem"
            *ngIf="config.signal && !config.booleanOption"
            [style.backgroundColor]="getData(item, config.signal.color)"
          ></div>
          <div class="labelcolumnitem" *ngIf="config.booleanOption">
            <div
              [ngClass]="getBooleanOpt(getData(item, config.key), config.booleanOption)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
