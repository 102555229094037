import { Component } from '@angular/core';
import {
  ITableColumnConfig,
  ITableHeaderConfig,
} from 'src/app/components/tablecomponent/tablecomponent.constants';
import { CaduserComponent } from 'src/app/modals/caduser/caduser.component';
import { UserModel } from 'src/app/models/usersmodel';
import { ModalService } from 'src/app/services/modalservice/modalservice.service';
import { UsersRepository } from 'src/app/services/repository/users.repository';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  public loading: boolean = false;
  public list: UserModel[] = [];

  /**
   * Configurações de table
   */
  public configTable: ITableColumnConfig[] = [
    {
      key: 'idUsuario',
      label: 'ID',
      size: '100px',
      columnsize: 7.5,
      alignment: 'center',
    },
    { key: 'Nome', label: 'Nome', size: '1fr', columnsize: 40 },
    { key: 'Usuario', label: 'Usuario', size: '1fr', columnsize: 40 },
    { key: 'Funcao', label: 'Função', size: '1fr', columnsize: 40 },
  ];

  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  constructor(
    private repo: UsersRepository,
    private modal: ModalService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  public async init() {
    this.loading = true;
    try {
      this.list = ArrayInterfaceToArrayClass(
        await this.repo.getAll(),
        UserModel
      );
      console.log('[INFO] Listagem de usuários => ', this.list);
    } catch (error) {
      console.log('[ERROR] Init => ', error);
    }
    this.loading = false;
  }

  public async new(data: UserModel = new UserModel()) {
    try {
      this.modal
        .show(CaduserComponent, {
          height: 'auto',
          width: '566px',
          data: data,
        })
        .then((_) => this.init());
    } catch (error) {}
  }
}
