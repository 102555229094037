import { Component, OnInit } from '@angular/core';
import {
  ITableColumnConfig,
  ITableHeaderConfig,
} from 'src/app/components/tablecomponent/tablecomponent.constants';
import { CadgatewayComponent } from 'src/app/modals/cadgateway/cadgateway.component';
import { GatewayModel } from 'src/app/models/gatewaymodel';
import { ModalService } from 'src/app/services/modalservice/modalservice.service';
import { GatewayRepository } from 'src/app/services/repository/gateway.repository';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';

@Component({
  selector: 'app-gateways',
  templateUrl: './gateways.component.html',
  styleUrls: ['./gateways.component.scss'],
})
export class GatewaysComponent implements OnInit {
  public loading: boolean = false;
  public list: GatewayModel[] = [];

  /**
   * Configurações de table
   */
  public configTable: ITableColumnConfig[] = [
    {
      key: 'idGateway',
      label: 'ID',
      size: '100px',
      columnsize: 7.5,
      alignment: 'center',
    },
    { key: 'Marca', label: 'Marca', size: '1fr', columnsize: 40 },
    { key: 'Modelo', label: 'Modelo', size: '1fr', columnsize: 40 },
    { key: 'ModbusAdd', label: 'Modbus Add.', size: '1fr', columnsize: 40 },
    { key: 'Tipo', label: 'Tipo', size: '1fr', columnsize: 40 },
    { key: 'NumeroSerial', label: 'Serial', size: '1fr', columnsize: 40 },
  ];

  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  constructor(private gatewayrepo: GatewayRepository, private modal: ModalService) {}

  ngOnInit(): void {
    this.init();
  }

  public async init() {
    this.loading = true;
    try {
      this.list = ArrayInterfaceToArrayClass(
        await this.gatewayrepo.getAll(),
        GatewayModel
      );
      console.log('[INFO] Listagem de gateways => ', this.list);
    } catch (error) {
      console.log('[ERROR] Init => ', error);
    }
    this.loading = false;
  }

  public async new(data: GatewayModel = new GatewayModel()) {
    try {
      this.modal
        .show(CadgatewayComponent, {
          height: 'auto',
          width: '566px',
          data: data,
        })
        .then((_) => {
          this.init();
        });
    } catch (error) {}
  }
}
