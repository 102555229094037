import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { BacklogComponent } from './components/backlog/backlog.component';
import { BridgedrawingComponent } from './components/bridgedrawing/bridgedrawing.component';
import { BridgesComponent } from './pages/bridges/bridges.component';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TablecomponentComponent } from './components/tablecomponent/tablecomponent.component';
import { IconComponent } from './components/icon/icon.component';
import { GatewaysComponent } from './pages/gateways/gateways.component';
import { DevicesComponent } from './pages/devices/devices.component';

import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { environment as env } from '../environments/environment';
import { EventMqttService } from './services/mqtt/event.mqtt.service';
import { MqttClientService } from './services/mqtt/mqtt.service';
import { LabeltagComponent } from './components/labeltag/labeltag.component';
import { UsersComponent } from './pages/users/users.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { CaduserComponent } from './modals/caduser/caduser.component';
import { CadbridgeComponent } from './modals/cadbridge/cadbridge.component';
import { InputcomponentComponent } from './components/inputcomponent/inputcomponent.component';
import { ButtoncomponentComponent } from './components/buttoncomponent/buttoncomponent.component';
import { CadgatewayComponent } from './modals/cadgateway/cadgateway.component';
import { InvertersComponent } from './pages/inverters/inverters.component';
import { CadinverterComponent } from './modals/cadinverter/cadinverter.component';
import { SelectcomponentComponent } from './components/selectcomponent/selectcomponent.component';
import { LoadcellsComponent } from './pages/loadcells/loadcells.component';
import { CadloadcellComponent } from './modals/cadloadcell/cadloadcell.component';
import { BillboardComponent } from './components/billboard/billboard.component';
import { SwitchComponent } from './components/switch/switch.component';


const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: env.mqtt.server,
  port: env.mqtt.port,
  username: env.mqtt.user,
  password: env.mqtt.password,
  path: '',
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    SpinnerComponent,
    LoginComponent,
    TablecomponentComponent,
    IconComponent,
    BacklogComponent,
    InputcomponentComponent,
    ButtoncomponentComponent,
    BridgedrawingComponent,
    SelectcomponentComponent,
    BillboardComponent,
    SwitchComponent,
    BridgesComponent,
    GatewaysComponent,
    DevicesComponent,
    LabeltagComponent,
    UsersComponent,
    CaduserComponent,
    CadbridgeComponent,
    CadgatewayComponent,
    InvertersComponent,
    CadinverterComponent,
    LoadcellsComponent,
    CadloadcellComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    BrowserAnimationsModule,
    MatDialogModule,
    MatTooltipModule,
    FormsModule
    
  ],
  providers: [EventMqttService,MqttClientService],
  bootstrap: [AppComponent],
})
export class AppModule {}
