import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/usersmodel';
import { sendAlert } from 'src/app/services/alert/sendalert';
import { UsersRepository } from 'src/app/services/repository/users.repository';
import { StoreService } from 'src/app/services/storage/store.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loading: boolean = false;
  public user: UserModel = new UserModel();

  constructor(
    private repo: UsersRepository,
    private router: Router,
    private storage: StoreService
  ) {}

  ngOnInit(): void {
    
  }

  public async login() {
    this.loading = true;
    try {
      const res = await this.repo.login(this.user);
      console.log('[INFO] Usuário conectado => ', res);
      if (res) {
        this.storage.user = this.user;
        this.router.navigate(['home']);
      }
    } catch (error) {
      sendAlert('Usuário não conectado, verifique e tente novamente!');
    }
    this.loading = false;
  }
}
