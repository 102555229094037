import { Injectable } from '@angular/core';
import { IGateway } from 'src/app/interfaces/gatewayinterface';
import { ConnectionService } from './connection.service';
import { ENDPOINTS } from './enpoints';
import { GatewayModel } from 'src/app/models/gatewaymodel';

@Injectable({
  providedIn: 'root',
})
export class GatewayRepository {
  constructor(private conn: ConnectionService) {}

  async getAll(): Promise<IGateway[]> {
    try {
      return (await this.conn.get(ENDPOINTS().gateways)) as IGateway[];
    } catch (error) {
      console.error(error);
    }
  }

  async insert(item: GatewayModel) {
    try {
      if (item.idGateway) {
        return await this.conn.put(ENDPOINTS().gateways, item.toJson());
      }
      return await this.conn.post(ENDPOINTS().gateways, item.toJson());
    } catch (error) {}
  }
}
